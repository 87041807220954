<template>
    <v-breadcrumbs :items="items" :large="large" :class="`breadcrumbs pl-0 ` + textcolor">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item
                :disabled="item.disabled"
                :to="{name: item.to}"
                exact
            >
                {{item.text}}
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>

<script>
export default {
    name: 'Breadcrumbs',
    props: ['items', 'large', 'textcolor'],
}
</script>

<style lang="scss">
.breadcrumbs{
    padding: 0;
    .v-breadcrumbs__divider{
        padding: 0 5px !important;
    }
    .v-breadcrumbs__item{
        font-size: 16px;
        line-height: 24px;
        color: #808080;
        cursor: pointer;
    }
    .v-breadcrumbs__item.v-breadcrumbs__item--disabled{
        font-size: 16px;
        line-height: 24px;
        color: #1F1F1F;
        cursor: default;
    }
}
.breadcrumbs.textwhite{
    .v-breadcrumbs__divider{
        padding: 0 5px !important;
        color: #91B9FB !important;
    }
    .v-breadcrumbs__item{
        color: #91B9FB;
    }
    .v-breadcrumbs__item.v-breadcrumbs__item--disabled{
        color: #fff;
    }

}
.breadcrumbs.textdark{
    .v-breadcrumbs__divider{
        padding: 0 5px !important;
        color: #808080;
    }
    .v-breadcrumbs__item{
        color: #808080;
    }
    .v-breadcrumbs__item.v-breadcrumbs__item--disabled{
        color: #1F1F1F;
    }

}
</style>
