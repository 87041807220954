<template>
    <layout>
        <template v-slot:header>
            <div class="pb-16">
                <v-container>
                    <breadcrumbs :items="breadcrumbs" :large="true" class="py-7 mb-7" :textcolor="`textwhite`"></breadcrumbs>
                    <h1 class="heading-2 white--text mb-10">Contact Us</h1>
                    <contact-us
                        nohead="true"
                        subtitle="If you have any questions please <br/> leave an application or don't hesitate <br/> to contact us directly"
                    />
                </v-container>
            </div>
        </template>
    </layout>
</template>

<script>
import Layout from '@/components/layouts/BlueTopLayout.vue'
import Breadcrumbs from '@/components/base/Breadcrumbs.vue'
import ContactUs from '@/components/pages/ContactUs.vue'

export default {
    components: {
        Layout,
        Breadcrumbs,
        ContactUs
    },
    data: () => ({
        breadcrumbs: [
            {
                text: 'Home page',
                to: 'frontend.home',
                disabled: false,
            },
            {
                text: 'Contact us',
                disabled: true,
            }
        ]
    }),
}
</script>

<style lang="scss">
</style>
